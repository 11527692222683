*{
  box-sizing: border-box;

}

body {
  margin: 20px;
  font-family: 'Cinzel', serif;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  max-width: 370px;
    height: 100vh;
    margin: 0 auto;
}

.flex {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}
.nav_bar{
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
}
.menu{
  justify-content: center;
}
.menu ul{
  padding-inline-start: 0px;
  width: 100%;
}
.menu a{
  width: 90%;
  justify-content: center;
}

.menu-btn{
  width: 100%;
  font-family: 'Cinzel', serif;
  font-size: x-large;
  font-weight: bold;
  background: #f4f1ec;
  color: #d8bc7a;
}
li{
  list-style-type: none;
}
.menu-btn:hover{
  background: #a6a4a1;
}

.btn-50{
  width: 40%;
  font-family: 'Cinzel', serif;
  font-size: large;
  font-weight: bold;
  background: #f4f1ec;
  color: #d8bc7a;
}
.btn-50:hover{
  background: #a6a4a1;
}
.btn{
  width: 100%;
  font-family: 'Cinzel', serif;
  font-size: large;
  font-weight: bold;
  background: #f4f1ec;
  color: #d8bc7a;
}
.btn:hover{
  background: #a6a4a1;
}
.groupe-btn {
  justify-content: space-evenly;
  width: 100%;
}
.centre{
  justify-content: center;
}
.groupe-btn a{
  width: 45%;
}

.menu_logo{
  margin-bottom: 35px;
  width: 100%;
}

.gris {
  color: #ffffff;
  background: #ada48d;
}

.jaune {
  color: #ffffff;
  background: #efbe4c;
}

.rouge {
  color: #ffffff;
  background: #c3181b;
  ;
}

.vert {
  color: #ffffff;
  background: #2e8f44;
}

#cartouche{
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  
}

#cartouche_nom{
  width: 100%;
  text-align: center;
  padding: 5px;
}

#cartouche_description{
  width: 100%;
  padding: 3px 2px 3px 38px
}
.score{
  height: 100%;
  align-content: flex-start;
  align-items: flex-start;
}

#corp{
  width: 100%;
    max-height: 45vh;
    overflow: scroll;
    align-items: flex-start;
    align-content: flex-start;
    margin-bottom: 15px;
}

.calculer{
  width: 100%;
  font-family: 'Cinzel', serif;
  font-size: x-large;
  font-weight: bold;
  background: #f4f1ec;
  color: #d8bc7a;
}

.calculer:hover{
  background: #a6a4a1;
}

#corp::-webkit-scrollbar {
  display: none;
}

.Alexandrie #cartouche_nom{
  color: white;
  background: #12589f;
}

.Alexandrie #cartouche_description{
  background: #c6e8f5;
}

.Halicarnasse #cartouche_nom{
  color: white;
  background: #574981;
}

.Halicarnasse #cartouche_description{
  background: #e0dfef;
}

.Ephese #cartouche_nom{
  color: white;
  background: #da6b60;
}

.Ephese #cartouche_description{
  background: #fcd2c0;
}

.Babylone #cartouche_nom{
  color: white;
  background: #157658;
}

.Babylone #cartouche_description{
  background: #c0e2ca;
}

.Rhodes #cartouche_nom{
  color: white;
  background: #ef4641;
}

.Rhodes #cartouche_description{
  background: #f9beb7;
}

.Olympie #cartouche_nom{
  color: white;
  background: #fec75a;
}

.Olympie #cartouche_description{
  background: #fffbd6;
}

.Gizeh #cartouche_nom{
  color: white;
  background: #bd5f27;
}

.Gizeh #cartouche_description{
  background: #fcd1af;
}

#main{
  width: 100%;
}
#nav_bar{
  width: 100%;
  margin: 5px 0;
}
#nav_merveille{
  background-color: #f9f4ea;
    flex-grow: 1;
    text-align: center;
}
#nav_civil_millitaire{
  background: linear-gradient(to right, #c6e8f5, #fabeb7);
  flex-grow: 1;
  text-align: center;
}
#nav_Sience{
    background-color: #c2e2ca;
    flex-grow: 1;
    text-align: center;
}
.merveille_div {
  width: 100%;
  justify-content: center;
}
.etage{
  justify-content: center;
    width: 100%;
}

#Alexandrie #etage0 img {
  justify-content: center;
    width: 100%;
    margin-top: -10px;
}

#Alexandrie #etage1 img {
  justify-content: center;
    width: 70%; 
}

#Alexandrie #etage2 img {
  justify-content: center;
    width: 75%;
    margin-top: -33px;
}

#Alexandrie #etage3 img {
  justify-content: center;
    width: 40%;
    margin-top: -3px; 
}

#Alexandrie #etage4 img {
  justify-content: center;
    width: 40%; 
}

#Gizeh #etage0 img  { 
  width: 100%;
}

#Gizeh #etage1 img {
  justify-content: center;
  width:84%;    
}

#Gizeh #etage2 img {
  justify-content: center;
  width: 66%;    
}

#Gizeh #etage3 img {
  justify-content: center;
  width: 48%;   
}

#Gizeh #etage4 img {
  justify-content: center;
  width: 29%;   
}

#Halicarnasse #etage0 img {
  justify-content: center;
  width: 100%;
  
  
  
  
}

#Halicarnasse #etage1 img {
  justify-content: center;
  width: 92%;
  margin-top: -27px;
}

#Halicarnasse #etage2 img {
  margin-left: 40%;
  justify-content: space-between;
  width: 93%;
  margin-top: 1px;
  z-index: 1; 
}
#Halicarnasse #etage2{
  justify-content: space-between;
  width: 33%;  
}

#Halicarnasse #etage3 img {
  justify-content: space-between;
  width: 100%;
  margin-left: -42%;
  z-index: 1;   
}
#Halicarnasse #etage3{
  justify-content: space-between;
  width: 33%;   
}

#Halicarnasse #etage4 img {
  justify-content: center;
  width: 100%;
  margin-bottom: -1px;
}

#Ephese #etage0 img {
  justify-content: center;
  width: 100%;   
}

#Ephese #etage1 img {
  justify-content: center;
  width: 100%;
  margin-left: 186%;  
}
#Ephese #etage1{
  justify-content: center;
  width: 20%;   
}

#Ephese #etage2 img {
  justify-content: center;
  width: 100%;
  margin-top: -6px;    
}
#Ephese #etage2{
  justify-content: center;
  width: 20%;    
}

#Ephese #etage3 img {
  justify-content: center;
  width: 100%;
  margin-left: -141%;;
  margin-top: -4px;   
}
#Ephese #etage3{
  justify-content: center;
  width: 20%;    
}


#Ephese #etage4 img {
  justify-content: center;
  width: 100%;    
}

#Babylone #etage0 img {
  justify-content: center;
  width: 100%;    
}

#Babylone #etage1 img {
  justify-content: center;
  width: 100%;    
}

#Babylone #etage2 img {
  justify-content: center;
  width: 100%;    
}

#Babylone #etage3 img {
  justify-content: center;
  width: 73%;  
}
#Babylone #etage3{
  justify-content: center;
  width: 50%;    
}    
#Babylone #etage4 img {
  justify-content: center;
  width: 65%;
  margin-bottom: -42%;    
}
#Babylone #etage4{
  justify-content: center;
  width: 50%;
  margin-top: 20.5%;    
}
#Olympie #etage0 img {
  justify-content: center;
  width: 100%;    
}

#Olympie #etage1 img {
  justify-content: center;
  width: 87%;
  margin-top: -1px;
  margin-left: -15%;    
}
#Olympie #etage1{
  justify-content: center;
  width: 50%;    
}
#Olympie #etage2 img {
  justify-content: center;
  width: 90%;
  margin-top: -4px;
  margin-left: 15%;    
}
#Olympie #etage2{
  justify-content: center;
  width: 50%;    
}
#Olympie #etage3 img {
  justify-content: center;
  width: 86%;
  margin-top: -43px;
  z-index: 1;   
}

#Olympie #etage4 img {
  justify-content: center;
  width: 81%;   
}

#Rhodes #etage0 img {
  justify-content: center;
  width: 100%;
}
#Rhodes #etage0{
  justify-content: center;
  width: 50%;
}
#Rhodes #etage1 img {
  justify-content: center;
  width: 100%;    
}
#Rhodes #etage1{
  justify-content: center;
  width: 50%;    
}
#Rhodes #etage2 img {
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;    
}

#Rhodes #etage3 img {
  justify-content: center;
  width: 100%;    
}

#Rhodes #etage4 img {
  justify-content: center;
  width: 93%;    
}


.nom {
  width: 100%;
  text-align: center;
}
.effet {
  font-size: 0.75em;
  width: 70%;
  padding-left: 10px;
  align-self: center;
}
.card {
    width: 100%;
    align-items: center;
    padding: 5px;
}
.description{
    width: 80%;
}
.civils {
  background: #c6e8f5;
}

.millitaires {
   background: #fabeb7;
}
.img{
  width: 60px;
}
.img{
  width: 60px;
}

.progre:nth-child(2n) {
  background: #c2e2ca;
}
.clasement{
  width: 100%;
}
.best{
  width: 100%;
  min-height: 50px;
  border: solid lightgray;
  margin: 5px;
  justify-content: space-between;
}
.best span{
  padding-right: 10px;
  align-self: center;
  font-size: 17px;
}

.merveille{
  background-color: #ebcc1169;
}
.civile{
  background-color: #0077ff4d;
}
.militaire{
  background-color: #ff000061;
}
.science {
  background-color: #00ff1a2e;
}

.best img{
  width: 75px;
  padding-right: 10px;
  padding-left: 5px;
}

.card-partie{
    
    border: solid 2.5px #494c50;
    box-shadow: 4px 5px 4px lightgrey;
    padding: 10px;
    margin: 15px;
    border-radius: 10px;
    background: linear-gradient(#fdeee4,#cbbbab);
}

.nom-partie{
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: solid 0.1px #0000006b;
    text-align: center;
}

.info-partie{
  width: 100%;
}

.btn-partie{
    height: 100%;
    align-self: center;
    width: 100%;
    margin-top: 15px;
}
table{
  width: 100%;
}
table, th, td{
  border-collapse: collapse;
  border: 0.1px #050505;
  border-style: solid;
  text-align: center;
  white-space: pre-line;
  margin-bottom: 15px;
}
thead{
  background-color: blanchedalmond;
}
tr{
  border: #838181 solid;
}

td div{
  width: 100%;
  text-align: left;
  font-size: x-large;
}

th img{
  width: 100%;
  max-width: 70px;
}

.classement{
  width: 100%;
}

.nom_merveille{
  font-size: x-small;
    position: relative;
    top: -5px;
}
.demandes{
  width: 100%;
  justify-content: center;
}
.demande{
  padding: 10px;
  width: 80%;
  border: solid 1px ;
  border-radius: 15px;
  margin: 10px;
  align-items: center;
  justify-content: center;
  background: #e1e0b9;
}

.info_demande{
  text-align: center;
  margin-bottom: 3px;
  width: 100%;
  border-bottom: solid 0.5px ;
}
.groupe-form{
  margin: 10px;
  justify-content: center;
}
.groupe-form label{
  text-align: center;
  width: 100%;
  margin-bottom: 5px;
}
.groupe-form input{
  width: 80%;
}
.form_groupe{
  width: 50%;
  margin: 5px 0px;
}
 .radio{
  width: 75%;
  justify-content: space-between;
 }
.checkbox{
  width: 100%;
  margin: 5px 0px 20px;
  justify-content: center;
}
 h1{
  text-align: center;
 }

 .mesage{
  justify-content: center;
  border-radius: 5px;
 }

 @keyframes clignoterRed{
  0% {background: #ff878700;}
  1% {background: #ff8787;}
  100% {background: #ff878700;}
 }

 @keyframes clignoterGreen{
  0% {background: #ff878700;}
  1% {background: #96ea9f;}
  100% {background: #ff878700;}
 }

 .ok{
  animation: 0.5s 1 clignoterGreen;
  color: green;
 }
 .nok{
  animation: 0.5s 1 clignoterRed;
  color: red;
 }

 .tete_beauf{
  width: 50%;
  justify-content: center;
  margin-bottom: 10px;
 }

 .tete_beauf label{
  margin-right: 5px;
  width: 50px;
  
 }

 .point{
  justify-content: center;
  margin: 20px 0px;
 }


 *[data-color='bleu']{
  background:rgb(0, 0, 255) ;
  color: white;
 }
 *[data-color='bleu claire']{
  background:rgb(38, 196, 236) ;
  color: white;
 }
 *[data-color='jaune']{
  background:rgb(255, 255, 0);
  color: black;
 }
 *[data-color='or']{
  background:rgb(255, 215, 0) ;
 }
 *[data-color='rose']{
  background:rgb(249, 66, 158) ;
  color: white;
 }
 *[data-color='rouge']{
  background:rgb(255, 0, 0) ;
  color: white;
 }
 *[data-color='vert claire']{
  background:rgb(84, 249, 141) ;
  color: black;
 }
 *[data-color='vert foncé']{
  background:rgb(20, 148, 20) ;
  color: black;
 }
 *[data-color='violet']{
  background:rgb(102, 0, 153) ;
  color: white;
 }
 *[data-color='cerise']{
  background:rgb(222, 49, 99) ;
  color: white;
 }

 body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}